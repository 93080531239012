<template>
  <v-app>
    <v-container fluid>
        <body class="application application-offset">
            <div class="container-fluid container-application">
                <mainMenu /> <!-- ==== 左選單 ==== -->

                <div class="main-content position-relative">
                    <mainNav /> <!-- ==== Top選單 ==== -->

                    <div class="page-content index">
                        <!-- Page title -->
                        <div class="page-title">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-md-6 mb-3 mb-md-0" style="z-index:5">
                                    <h5 class="h3 font-weight-400 mb-0 text-white">嗨，{{userName}}!</h5>
                                    <span class="text-sm text-white opacity-8">祝你有個美好的一天!</span>
                                </div>
                            </div>
                        </div>
                        
                        <div class="card" style="z-index: 2;">
                            <div class="card-header actions-toolbar border-0">
                                <div class="row justify-content-between align-items-center">
                                    <div class="col" style="padding-top: 0px;padding-bottom: 0px;">
                                        <h2 class="d-inline-block mb-0 card-title">入出紀錄明細</h2>
                                    </div>
                                </div>
                            </div>
                            <!-- ==========主要內容區========== -->
                            <br/>
                            <v-row>
                                <v-col class="px-10">
                                    <v-select
                                        :items="orgItems"
                                        label="事業單位名稱"
                                        v-model="orgId"
                                        outlined
                                        :readonly="loginAuth.role !== 'S'"
                                        style="z-index:999;font-size: 18px;font-weight: bold;"
                                    ></v-select>
                                </v-col>
                                <v-col class="px-10">
                                    <v-select
                                        v-model="jsId"
                                        :items="jobSiteList"
                                        label="場域"
                                        item-text="optionText"
                                        item-value="optionValue"
                                        outlined
                                        style="z-index:999;font-size: 18px;font-weight: bold;"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="px-10">
                                    <v-select
                                        v-model="ioName"
                                        :items="ioNameList"
                                        label="場域出入口"
                                        item-text="optionText"
                                        item-value="optionValue"
                                        outlined
                                        style="z-index:999;font-size: 18px;font-weight: bold;"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="px-10">
                                    <v-select
                                        v-model="inorout"
                                        :items="inoroutList"
                                        label="入出場狀態"
                                        item-text="text"
                                        item-value="value"
                                        outlined
                                        style="z-index:999;font-size: 18px;font-weight: bold;"
                                    ></v-select>
                                </v-col>
                                <v-col class="px-10 d-none d-md-flex">
                                    <v-menu
                                        v-model="dateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="selectDate"
                                            label="入出場日期"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            outlined
                                            v-bind="attrs"
                                            v-on="on"
                                            clearable
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        v-model="selectDate"
                                        @input="dateMenu = false"
                                        class="d-none d-md-flex"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <!-- <v-row>
                                <v-col class="px-10 d-flex d-md-none">
                                    <v-menu
                                        v-model="dateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="selectDate"
                                            label="入出場日期"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            outlined
                                            v-bind="attrs"
                                            v-on="on"
                                            clearable
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        v-model="selectDate"
                                        @input="dateMenu = false"
                                        class="d-flex d-md-none"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row> -->
                            <v-row>
                                <v-col class="px-10">
                                    <div>入場人數：{{numberOfPeopleIn}}</div>
                                    <div>出場人數：{{numberOfPeopleOut}}</div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <div class="v-data-footer">
                                        <div class="v-data-footer__select">每頁列數:<v-select
                                        v-model="listPageSize"
                                        :items="pageSizeList"
                                        dense
                                        @change="nextPage(1, listPageSize)"
                                        ></v-select></div>
                                        <div class="v-data-footer__pagination">
                                        共 {{pageInfo.totalCount}} 筆資料，全部 {{pageInfo.maxPage}} 頁，目前第{{pageInfo.currentPage}}頁
                                        <v-icon class="mx-4" @click="nextPage(pageInfo.currentPage-1, listPageSize)" :disabled="pageInfo.currentPage == 1">mdi-chevron-left</v-icon>
                                        <v-icon class="mx-4" @click="nextPage(pageInfo.currentPage+1, listPageSize)" :disabled="pageInfo.currentPage >= pageInfo.maxPage">mdi-chevron-right</v-icon>
                                        </div>
                                    </div>
                                    <v-data-table
                                    :headers="headers"
                                    :items="datalist"
                                    :items-per-page="listPageSize"
                                    hide-default-footer
                                    disable-sort
                                    class="mx-12"> 
                                        <template v-slot:[`item.orgnizationMember.memberPropertyList`]={item}>
                                            <v-select
                                                v-model="item.orgnizationMember.memberPropertyList"
                                                :items="memberPropertyList"
                                                item-text="text"
                                                item-value="value"
                                                attach
                                                chips
                                                multiple
                                                readonly
                                                append-icon=""
                                            >
                                                <template #selection="{ item }">
                                                    <v-chip class="ma-2 white--text" color="red darken-4" v-if="item.value === 'nonmember'">{{item.text}}</v-chip>
                                                    <v-chip class="ma-2" color="primary" v-if="item.value !== 'nonmember'">{{item.text}}</v-chip>
                                                </template>
                                            </v-select>
                                        </template>
                                    </v-data-table>
                                    <br/>
                                </v-col>
                            </v-row>
                            <!-- ==========主要內容區========== -->
                        </div>

                    </div>

                    <mainFooter /><!-- Footer -->
                    
                </div>
            </div>
        </body>
        <loadingMask :showLoading="isLoading"  style="z-index:9999"></loadingMask>
    </v-container>
  </v-app>
</template>

<script>
import mainMenu from '@/components/mainMenu.vue'
import mainNav from '@/components/mainNav.vue'
import mainFooter from '@/components/mainFooter.vue'
import commonMixin from '@/utils/commonMixin';
import loadingMask from '@/components/public/loadingMask.vue'
import {getInoutRecordList, getOrgnizationList, getJobSiteNameSelectOption, getSysParamListByCode} from "@/api/hyirecoApi";

export default {
  name: 'orgnizationList',
  mixins: [commonMixin],
  created(){
    this.authValidate();
    this.auth = {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getTokenWithSession(),
        }
    };
    // var date = new Date();
    // var dateStr = [
    //     date.getFullYear(),
    //     ((date.getMonth() + 1) > 9 ? '' : '0') + (date.getMonth() + 1),
    //     (date.getDate() > 9 ? '' : '0') + date.getDate()
    // ].join('-');
    // this.selectDate = dateStr;
  },
  destroyed() {
        clearInterval(this.interval);
  },
  mounted(){
    //console.log("loginAuth" + JSON.stringify(this.loginAuth));
    this.userName = this.loginAuth.memberName;
    this.getMemberProperty();
    this.getOrgDataList();
    if (this.loginAuth.role !== "S") {
        if (this.$route.params.orgId === this.loginAuth.orgnization.orgId) {
            this.orgId = this.loginAuth.orgnization.orgId;
        } else {
            this.$swal("資料錯誤");
            this.$router.push({ path: "/inoutRecord/inoutRecord" });
        }
    } else {
        this.orgId = this.$route.params.orgId;
    }
    
    this.selectDate = this.$route.params.selectDate;
  },
  computed: {
    headers() {
      return [
          { text: '人員姓名', value: 'orgmName', class: "subtitle-2 font-weight-black",width:150,},
          { text: '人員屬性', value: 'memberPropertyList', class: "subtitle-2 font-weight-black",},
          { text: '出入口', value: 'ioName', class: "subtitle-2 font-weight-black",width:150,},
          { text: '入場時間', value: 'inTime', class: "subtitle-2 font-weight-black",width:80, },
          { text: '出場時間', value: 'outTime', class: "subtitle-2 font-weight-black",width:80,},
          { text: '入出場狀態', value: 'inoroutCH', class: "subtitle-2 font-weight-black",width:80,},
      ]
    },
  },
  methods: {
    getMemberProperty: function(){
      var vm = this;
      
      getSysParamListByCode("people",vm.auth).then(function(response) {
        if ("200" == response.status) { 
          var responseData = response.data;
          if (responseData.statusCode === "1") {
            responseData.sysParam.childParamList.forEach(function(para){
              var tmpArr =  para.childParamList.map(function(child){
                              var item = new Object();
                              item.text = para.name + "-" + child.name;
                              item.value = child.code;
                              return item;
                            });
            //   console.log("tmpArr = " + JSON.stringify(tmpArr));
              vm.memberPropertyList = vm.memberPropertyList.concat(tmpArr);
            });
            var item = new Object();
            item.text = "非場域人員";
            item.value = "nonmember";
            vm.memberPropertyList.push(item);
          }
        }
      });
    },
    getOrgDataList: function() { 
        var vm = this;
        vm.isLoading = true;
        
        getOrgnizationList(vm.queryRequest, vm.auth).then(function(response) {
            if ("200" == response.status) { 
                var responseData = response.data;
                //console.log("response statusCode = " + JSON.stringify(responseData));
                
                if (responseData.statusCode === "1") {
                    var datalist = responseData.queryPager.resultList;
                    vm.orgItems = datalist.map(function(item){
                        item.text = item.name;
                        item.value = item.orgId;
                        return item;
                    });
                }
            }
            vm.isLoading = false;
        });
       
    },
    getJSList: function() { 
        var vm = this;
        getJobSiteNameSelectOption(vm.orgId, vm.auth).then(function(response) {
            if ("200" == response.status) { 
                var responseData = response.data;
                // console.log("responseData = " + JSON.stringify(responseData));
                if (responseData.statusCode === "1") {
                    vm.jobSiteList = responseData.jobSiteNameSelectOption;
                    
                    // vm.jobSiteList = data.map(function(item) {
                    //     var obj = new Object();
                    //     obj.text = item.jsName;
                    //     obj.value = item.jsId;

                    //     return obj;
                    // });

                    // if (vm.jobSiteList.length > 0) {
                    //     vm.jsId = vm.jobSiteList[0].optionValue;
                    // }
                    
                    if (vm.loginAuth.role !== "S") {
                        if (vm.jobSiteList.some(function(item){
                            return item.optionValue == vm.$route.params.jsId;
                        })) { 
                            vm.jsId = vm.$route.params.jsId;
                        } else {
                            vm.$swal("資料錯誤");
                            vm.$router.push({ path: "/inoutRecord/inoutRecord" });
                        }
                    } else {
                        vm.jsId = vm.$route.params.jsId;
                    }
                } else {
                    vm.datalist = [];
                    if (responseData.statusCode !== "2") vm.$swal(responseData.message);
                }
            }
        });
       
    },
    getDataList: function() { 
        var vm = this;
        vm.isLoading = true;
        
        getInoutRecordList(vm.queryRequest, vm.auth).then(function(response) {
            if ("200" == response.status) { 
                var responseData = response.data;
                //onsole.log("response = " + JSON.stringify(responseData));
                
                if (responseData.statusCode === "1") {
                    vm.numberOfPeopleIn = responseData.numberOfPeopleIn;
                    vm.numberOfPeopleOut = responseData.numberOfPeopleOut;
                    vm.datalist = responseData.queryPager.resultList;
                    // console.log("datalist = " + JSON.stringify(vm.datalist));

                    vm.datalist = vm.datalist.map(function(item) {
                        item.inoroutCH = item.inorout === "I" ? "入場" : "出場";
                        //console.log(JSON.stringify(item.orgnizationMember));
                        if (item.recognizeResult === "N") {
                            item.orgnizationMember = {};
                            item.orgnizationMember.memberPropertyList = [];
                            item.orgmName = item.detectRecord.egroupAiMessage;
                        }

                        if (item.nonmember === "Y") {
                            item.orgnizationMember.memberPropertyList.push("nonmember");
                        }

                        return item;
                    });
                
                    vm.pageInfo.maxPage = responseData.queryPager.maxPage;
                    vm.pageInfo.currentPage = responseData.queryPager.currentPage;
                    vm.pageInfo.totalCount = responseData.queryPager.totalCount;
                    vm.$swal.close();
                } else {
                    vm.numberOfPeopleIn = 0;
                    vm.numberOfPeopleOut = 0;
                    vm.datalist = [];
                    vm.$swal(responseData.message);
                }
            }
            vm.isLoading = false;
        });
       
    },
    keyupDelay: function() {
        //用於延遲查詢，避免value改變時連續送出request
        let vm = this;
        clearTimeout(vm.timer);
        
        vm.timer = setTimeout(function() {
            vm.getDataList();
        }, 800);
    },
    nextPage: function(currentPage, pageSize) {
      //console.log("next page = " + value);
      let vm = this;
      vm.queryRequest.queryPager.pageSize = pageSize;
      vm.queryRequest.queryPager.currentPage = currentPage;
      vm.getDataList();
    },
  },
  watch: {
      orgId: function(){
        this.getJSList();
      },
      jsId: function(value){
        let vm = this;
        vm.queryRequest.queryObject.jsId = value;
        vm.getDataList();
        vm.jobSiteList.map(function(item) {
            if(item.optionValue === value)
                vm.ioNameList = JSON.parse(item.inout);
        });
      },
      ioName: function(value){
        this.queryRequest.queryObject.ioName = value;
        this.getDataList();
      },
      inorout: function(value) {
        this.queryRequest.queryObject.inorout = value;
        this.getDataList();
      },
      selectDate: function(value) {
        this.queryRequest.queryObject.ioDateStr = this.formatDate(value);
        this.getDataList();
      }
  },
  data: () => ({
    numberOfPeopleIn: 0,
    numberOfPeopleOut: 0,
    orgItems: [],
    jobSiteList:[],
    memberPropertyList:[],
    userName: "",
    datalist: [],
    orgId: "",
    jsId: "",
    ioName: "",
    ioNameList: [],
    inorout: "",
    selectDate: "",
    isLoading: false,
    dateMenu: null,
    auth:{},
    inoroutList:[
        {text:"全部", value:""},
        {text:"入場", value:"I"},
        {text:"出場", value:"O"},
    ],
    pageInfo: {
      maxPage: 1,
      currentPage: 1,
      totalCount: 0,
    },
    listPageSize: 10,
    pageSizeList: [
      {text:"10", value:10},
      {text:"30", value:30},
      {text:"50", value:50},
    ],
    queryRequest: {
        queryObject: {
            jsId: "",
            inorout: "",
            ioDateStr: "",
            ioName: ""
        },
        queryPager: {
            currentPage: 1,
            pageSize: 10,
        },
    },
    jsQueryRequest: {
        queryObject: {
            jsName: "",
            status: "",
        },
        queryPager: {
            currentPage: 1,
            pageSize: 999,
        },
    },
  }),
  components: {
    mainMenu,
    mainNav,
    mainFooter,
    loadingMask,
  },
}
</script>

<style scoped>
    
</style>
